/*
  Theme Name: Bigtech - ICO & Crypto Landing Page Template
  Support: themegenix@gmail.com
  Description: Bigtech - ICO & Crypto Landing Page Template.
  Version: 1.0
*/

/* CSS Index
-----------------------------------

1. Theme default css
2. Header
3. Mobile-menu
4. Banner
5. Breadcrumb
6. Countdown
7. About
8. Choose
9. Chart
10. Counter
11. RoadMap
12. Document
13. Team
14. Download
15. Faq
16. Blog
17. Pagination
18. Contact
19. Newsletter
20. Footer
21. Preloader

*/

/* 1. Theme default css */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #c7d1db;
  font-style: normal;
  background-color: #17191d;
  line-height: 1.75;
  overflow-x: hidden;
  margin: 0;
}
body.white-background,
.white-background
{
  background-color: #ffffff;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
img,
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  margin-left: 6px;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #fdfdfe;
  border-radius: .25rem;
  color: #818182;
  background-color: #fefefe;
}
.alert-dark {
  background-color: #24262a;
  border-color: #34383d;
  color: #727885;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
.form-group label {
  display: inline-block;
  margin-bottom: .5rem;
}
.form-control {
  display: block;
  width: 100%;
  max-width: calc(100% - 1.5rem);
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
pre {
  background-color: #121212;
  color: #efefef;
  padding: 10px;
  overflow-x: auto;
}
a:focus,
.btn:focus,
button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: #d5c196;
  text-decoration: none;
}
a {
  color: #d5c196;
  outline: medium none;
  text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-top: 0px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 40px;
}
h2 {
  font-size: 35px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}
h6 {
  font-size: 16px;
}
ul {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;
}
p {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.75;
  color: #727885;
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid #eceff8;
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: #7e7e7e;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}
*::-moz-selection {
  background: #d5c196;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #d5c196;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #d5c196;
  color: #fff;
  text-shadow: none;
}
*::-moz-placeholder {
  color: #d5c196;
  font-size: 14px;
  opacity: 1;
}
*::placeholder {
  color: #555555;
  font-size: 14px;
  opacity: 1;
}
.theme-overlay {
  position: relative;
}
.theme-overlay::before {
  background: #1696e7 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.separator {
  border-top: 1px solid #f2f2f2;
}

/* Bootstrap 5 */
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.breadcrumb > .active {
  color: #888;
}

/* scrollUp */
.scroll-top {
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: fixed;
  bottom: 105%;
  right: 50px;
  font-size: 16px;
  border-radius: 50%;
  z-index: 99;
  color: #3d3d3d;
  text-align: center;
  cursor: pointer;
  background: #dcbc95;
  transition: 1s ease;
  border: none;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  background: #a3a3a3;
}

/* 2. Header */
.custom-container {
  max-width: 1630px;
}
.custom-container-two {
  max-width: 1700px;
}
.custom-container-three {
  max-width: 1520px;
}
.custom-container-four {
  max-width: 1200px;
}
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
.menu-area {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 140px;
}
.navbar-wrap > ul > li {
  display: block;
  position: relative;
  margin-right: 65px;
}
.navbar-wrap > ul > li > a {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;
  padding: 45px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  letter-spacing: 1px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap > ul > li > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #d5c196;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.navbar-wrap > ul > li.active > a::before,
.navbar-wrap > ul > li > a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
  color: #fff;
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul > li {
  position: relative;
  margin-left: 65px;
  padding-left: 65px;
}
.header-action > ul > li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.header-action > ul > li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 28px;
  background: #ffffff;
  opacity: 0.2;
}
.header-action > ul > li:first-child::before {
  display: none;
}
.header-lang {
  position: relative;
}
.header-lang .selected-lang {
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 1px;
}
.header-lang .selected-lang::after {
  content: "\f107";
  display: block;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: #d5c196;
  margin-left: 10px;
}
.header-lang .lang-list {
  position: absolute;
  left: -10px;
  top: calc(100% + 25px);
  background: #24262a;
  z-index: 3;
  padding: 10px 0;
  border-radius: 6px;
  box-shadow: 0px 30px 70px 0px rgba(40, 44, 49, 0.15);
  min-width: 100px;
  visibility: hidden;
  opacity: 0;
  transform-origin: top center;
  transform: perspective(400px) rotateX(-45deg);
  transition: all 0.3s ease-out 0s;
}
.header-lang:hover .lang-list {
  visibility: visible;
  opacity: 1;
  transform: perspective(400px) rotateX(0deg);
}
.header-lang .lang-list li {
  margin-bottom: 5px;
  line-height: 1;
}
.header-lang .lang-list li:last-child {
  margin-bottom: 0;
}
.header-lang .lang-list li a {
  display: block;
  font-size: 16px;
  color: #fff;
  padding: 5px 15px 5px 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.header-lang .lang-list li a:hover {
  color: #d5c196;
}
.header-btn .btn {
  font-size: 14px;
  padding: 20px 45px;
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(255, 255, 255, 0.07);
  background: #24262a;
  margin: 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 30px 70px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  line-height: 1.4;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
  font-size: 14px;
}
.navbar-wrap ul li .sub-menu li a:hover,
.navbar-wrap ul li .sub-menu li.active a {
  color: #d5c196;
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: #17191d;
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu .navbar-wrap > ul > li > a {
  padding: 45px 0;
}
#header-fixed-height.active-height {
  display: block;
  height: 105px;
}

/* header-two */
.menu-area.menu-style-two {
  border-bottom: none;
  padding: 30px 0;
}
.menu-style-two .navbar-wrap > ul > li > a {
  padding: 40px 0;
}
.menu-style-two .navbar-wrap > ul > li > a::before {
  display: none;
}
.menu-style-two .header-btn .btn {
  background: #d5c196;
  border-radius: 5px;
}
.menu-style-two .header-btn .btn::after {
  display: none;
}
.menu-style-two .header-btn .btn:hover {
  background: #564dca;
  border-color: #564dca;
  color: #fff;
}
.menu-area.menu-style-two.sticky-menu {
  padding: 0 0;
}
.menu-style-two .navbar-wrap > ul > li.active > a,
.menu-style-two .navbar-wrap > ul > li:hover > a {
  color: #d5c196;
}

/* 3. Mobile-menu */
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: #fff;
  margin-right: 30px;
  top: 15px;
}
.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  border-radius: 0px;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}
.mobile-menu .navbar-collapse {
  display: block !important;
}
.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu .navigation li.current > a:before {
  height: 100%;
}
.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: #24262a;
}
.mobile-menu-visible .menu-backdrop {
  opacity: 0.8;
  visibility: visible;
}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: #24262a;
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: #d5c196;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgb(255 255 255 / 6%);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 0.08em;
}
.mobile-menu .navigation li ul li > a {
  font-size: 15px;
  margin-left: 20px;
  text-transform: capitalize;
}
.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  background: #d5c196;
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
  border-color: #d5c196;
  background: #d5c196;
  color: #fff;
}
.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 30px;
  cursor: pointer;
  line-height: 1;
  color: #000;
  display: none;
  margin-top: 3px;
}

/* 4. Banner */
.banner-bg {
  position: relative;
  z-index: 1;
  padding: 120px 0 0;
  overflow: hidden;
}
.banner-bg::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0.23deg,
    #17191d 5.68%,
    rgba(3, 11, 21, 0.42) 81.9%
  );
  z-index: -1;
}
.banner-shape-wrap img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.banner-shape-wrap img.img-one {
  top: 28%;
  left: -3%;
  animation: leftToRight 5s infinite linear;
}
.banner-shape-wrap img.img-two {
  left: auto;
  right: 8%;
  top: 20%;
}
.banner-shape-wrap img.img-three {
  left: auto;
  right: -2%;
  top: auto;
  bottom: 14%;
  animation: alltuchtopdown 3s infinite linear;
}
.banner-content img {
  margin-bottom: 25px;
}
.banner-content .title {
  font-size: 55px;
  margin-bottom: 45px;
  line-height: 1.4;
  letter-spacing: -0.01em;
}
.banner-content .title span {
  color: #d5c196;
}
.banner-progress-wrap ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 50px;
}
.banner-progress-wrap ul li {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #d5c196;
  display: inline-block;
  position: relative;
  padding-bottom: 25px;
}
.banner-progress-wrap ul li::before {
  content: "";
  position: absolute;
  bottom: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 14px;
  background: #d5c196;
}
.banner-progress-wrap ul li:nth-child(2) {
  color: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3) {
  color: #12d176;
}
.banner-progress-wrap ul li:nth-child(2)::before {
  background: #ff9700;
}
.banner-progress-wrap ul li:nth-child(3)::before {
  background: #12d176;
}
.banner-progress-wrap {
  margin: 0 150px 70px;
}
.banner-progress-wrap .progress {
  height: 6px;
  background-color: #f2f2f2;
  border-radius: 0;
  overflow: inherit;
  margin-bottom: 25px;
  margin-top: 8px;
}
.banner-progress-wrap .progress .progress-bar {
  background-color: #d5c196;
  position: relative;
  overflow: inherit;
}
.banner-progress-wrap .progress .progress-bar::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  z-index: 5;
  border: 6px solid #d5c196;
}
.banner-progress-wrap .title {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.banner-progress-wrap .title span {
  margin-left: auto;
}
.banner-countdown-wrap .title {
  font-size: 26px;
  margin-bottom: 15px;
  letter-spacing: -0.01em;
}
.banner-countdown-wrap .coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.banner-countdown-wrap .coming-time .time-count {
  min-width: 162px;
  min-height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: 20px 17.5px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  background: #24262a;
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 5px;
  color: #a4b4c3;
  text-transform: capitalize;
}
.banner-countdown-wrap .coming-time .time-count span {
  font-size: 35px;
  font-weight: 600;
  color: #d5c196;
  margin-bottom: 12px;
  display: block;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.banner-countdown-wrap .coming-time .time-count.hour span {
  color: #ff9700;
}
.banner-countdown-wrap .coming-time .time-count.min span {
  color: #ff1d45;
}
.banner-countdown-wrap .coming-time .time-count.sec span {
  color: #12d176;
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}

/* banner-two */
.banner-area-two {
  position: relative;
  padding: 225px 0 0;
  z-index: 1;
}
.banner-bg-two {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 164px);
  z-index: -1;
}
.banner-bg-two::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 100%;
  height: 722px;
  z-index: -1;
}
.banner-area-two .banner-content img {
  margin-bottom: 0;
}
.banner-area-two .banner-content {
  margin-bottom: 0;
}
.banner-area-two .banner-content .title {
  margin-bottom: 65px;
  line-height: 1.36;
  text-transform: none;
}
.banner-social-wrap {
  position: absolute;
  left: 100px;
  bottom: 90px;
}
.banner-social-wrap ul li {
  margin-bottom: 25px;
  border: 1px solid #f3f3f3;
  border-radius: 50px;
  width: 42px;
  display: flex;
  align-items: center;
}
.banner-social-wrap ul li.is-active {
  width: auto;
}
.banner-social-wrap ul li:last-child {
  margin-bottom: 0;
}
.banner-social-wrap ul li a {
  font-size: 15px;
  color: #081a39;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  transition: 0s;
}
.banner-social-wrap ul li span {
  color: #b5bac4;
  font-size: 14px;
  font-weight: 500;
  padding-right: 14px;
  display: none;
}
.banner-scroll {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 40px;
  bottom: 90px;
}
.banner-scroll span {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.515em;
  color: #081a39;
  transform: rotate(-90deg);
  margin-bottom: 120px;
  opacity: 0.4;
}

/* 5. Breadcrumb */
.breadcrumb-bg {
  position: relative;
  padding: 235px 0 150px;
}
.breadcrumb-bg::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 19px;
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 65px;
  margin-bottom: 30px;
  letter-spacing: -0.01em;
  line-height: 1.15;
}
.breadcrumb-content .breadcrumb {
  display: flex;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
}
.breadcrumb-content .breadcrumb .breadcrumb-item {
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.27;
  text-decoration: underline;
}
.breadcrumb-content .breadcrumb .breadcrumb-item:first-child {
  text-decoration: none;
}
.breadcrumb-content .breadcrumb .breadcrumb-item a {
  color: #fff;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 15px;
  color: #ffffff;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-size: 17px;
  font-weight: 700;
  line-height: 1;
  opacity: 0.5;
  padding-top: 3px;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
  padding-left: 15px;
}

/* 17. Pagination */
.pagination-wrap ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 0 !important;
}
.pagination-wrap ul li {
  display: block;
  margin: 10px 3.7px 0;
}
.pagination-wrap ul li a {
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f2f5fa;
  border-radius: 5px;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  color: #8a879f;
}
.pagination-wrap ul li a:hover,
.pagination-wrap ul li .current {
  color: #fff;
  background: #564dca;
  border-color: #564dca;
}

/* Footer */
.footer-area {
  position: relative;
  z-index: 1;
}
.footer-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    359.43deg,
    #24262a 11.06%,
    rgba(11, 29, 51, 0) 99.43%
  );
  z-index: -1;
}
.footer-scroll-wrap {
  position: relative;
  z-index: 1;
}
.footer-scroll-wrap .scroll-to-target {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #17191d;
  border: 1px solid #1f262f;
  border-radius: 50%;
  font-size: 18px;
  padding: 0 0;
  color: #fff;
  margin: 0 auto;
}
.footer-scroll-wrap .scroll-to-target:hover {
  background: #d5c196;
  border-color: #d5c196;
}
.footer-scroll-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 1px;
  background: #1f262f;
  z-index: -1;
}
.footer-top {
  padding: 55px 0 50px;
}
.footer-widget {
  margin-bottom: 30px;
}
.footer-widget .f-logo {
  margin-bottom: 20px;
  display: block;
}
.footer-content p {
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 26px;
  color: #a4b4c3;
}
.footer-content .footer-social {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}
.footer-content .footer-social li {
  padding: 0 7px;
}
.footer-content .footer-social li a {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #d5c196;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
.footer-content .footer-social li a:hover {
  background: #d5c196;
  border-color: #d5c196;
}
.footer-widget .fw-title {
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 25px 0 27px;
}
.footer-link ul li {
  margin-bottom: 10px;
}
.footer-link ul li:last-child {
  margin-bottom: 0;
}
.footer-link ul li a {
  font-size: 15px;
  line-height: 20px;
  color: #a4b4c3;
  position: relative;
}
.footer-link ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link ul li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-link ul li a:hover {
  color: #fff;
}
.footer-top .row [class*="col-"]:nth-child(2) .footer-widget {
  margin-left: 80px;
}
.footer-top .row [class*="col-"]:nth-child(4) .footer-widget {
  margin-left: 75px;
}
.footer-newsletter p {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 60px;
  color: #a4b4c3;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  background: rgb(118 118 130 / 10%);
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  padding: 23px 80px 23px 25px;
  color: #fff;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}
.footer-newsletter form button {
  position: absolute;
  right: 0;
  top: 0;
  width: 65px;
  height: 72px;
  background: #d5c196;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  border: none;
}
.footer-bottom {
  border-top: 1px solid rgb(255 255 255 / 6%);
  padding: 27px 0;
}
.copyright-text p {
  color: #fff;
  margin-bottom: 0;
  font-size: 15px;
}
.footer-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-menu ul li {
  padding: 0 20px;
}
.footer-menu ul li a {
  font-size: 15px;
  color: #a4b4c3;
  font-weight: 500;
}
.footer-menu ul li a:hover {
  color: #fff;
}

/* footer-two */
.footer-area-two .footer-top {
  padding: 70px 40px 65px;
}
.footer-menu-two .navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.footer-menu-two .navigation li {
  margin-right: 60px;
}
.footer-menu-two .navigation li:last-child {
  margin-right: 0;
}
.footer-menu-two .navigation li a {
  color: #17191d;
  font-weight: 500;
  font-size: 16px;
}
.footer-menu-two .navigation li a:hover {
  color: #d5c196;
}
.footer-area-two .footer-social ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-area-two .footer-social ul li {
  margin-right: 40px;
}
.footer-area-two .footer-social ul li:last-child {
  margin-right: 0;
}
.footer-area-two .footer-social ul li a {
  color: #0a142f;
  opacity: 0.3;
  font-size: 24px;
  line-height: 1;
}
.footer-area-two .footer-social ul li a:hover {
  color: #d5c196;
  opacity: 1;
}
.footer-area-two .footer-bottom {
  border-top: 1px solid #f0f3f9;
  padding: 40px 40px;
}
.footer-area-two .copyright-text p {
  color: #282f3b;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.footer-area-two .scroll-up .scroll-to-target {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #564dca;
  padding: 0 0;
  border: none;
  color: #fff;
  font-size: 17px;
  margin: -60px auto 15px;
}
.footer-area-two .scroll-up .scroll-to-target:hover {
  background: #d5c196;
}
.footer-area-two .scroll-up span {
  display: block;
  color: #0a142f;
  font-size: 14px;
  font-weight: 500;
}
.footer-bottom-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer-bottom-menu ul li {
  margin-right: 30px;
}
.footer-bottom-menu ul li:last-child {
  margin-right: 0;
}
.footer-bottom-menu ul li a {
  font-size: 15px;
  color: #0a142f;
  font-weight: 500;
}
.footer-bottom-menu ul li a:hover {
  color: #d5c196;
}

img.app-icon {
  background-color: #24262a;
  border-radius: 34px;
  max-height: calc(180px - 24px);
  max-width: calc(180px - 24px);
}

@media (max-width: 767px) {
  img.app-icon {
    border-radius: calc(100vw / 10);
    max-height: calc(50vw - 24px);
    max-width: calc(50vw - 24px);
  }
}
